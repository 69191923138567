.cmp-experiencefragment .xf-content-height {
  margin: 0;
}

a {
  color: inherit;
  font-weight: 900;
  text-underline-offset: 3px;
}

a:hover {
  text-underline-offset: 5px;
  -webkit-transition: text-underline-offset 0.15s ease;
  transition: text-underline-offset 0.15s ease;
}

a:not(:hover) {
  text-underline-offset: 3px;
  -webkit-transition: text-underline-offset 0.3s ease;
  transition: text-underline-offset 0.3s ease;
}

.cmp-main-header .cmp-navigation__item-link,
.cmp-main-header .cmp-language-navigation__item-link {
  font-weight: 400;
}

.cmp-main-header .search {
  display: none;
}
/*# sourceMappingURL=theme.css.map */
