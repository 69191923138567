a {
  $original-offset: 3px;

  color: inherit;
  font-weight: 900;
  text-underline-offset: $original-offset;

  &:hover {
    text-underline-offset: 5px;
    transition: text-underline-offset 0.15s ease;
  }

  &:not(:hover) {
    text-underline-offset: $original-offset;
    transition: text-underline-offset 0.3s ease;
  }
}
