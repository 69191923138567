.cmp-main-header {

  .cmp-navigation__item-link,
  .cmp-language-navigation__item-link {
    font-weight: 400;
  }

  // deprecated, should be removed once header is replaced with ticketing header component in production
  .search {
    display: none;
  }

}
